import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScHeader3,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const content = `
<div class="idg-text-ajax-loading idg-text-copy-protection" data-text-id="2122722" data-text-language="de">
<h1 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="32" style="--fontSize:32; line-height: 1.4;" data-lineheight="44.8px">Datenschutzerklärung</h1>
<h2 id="einleitung-ueberblick" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Einleitung und Überblick</h2>
<p>Wir haben diese Datenschutzerklärung (Fassung 17.09.2022-112122722) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112122722#d1e2269-1-1" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche – und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) – verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br>
<strong class="adsimple-112122722">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
<p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong class="adsimple-112122722">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und <strong class="adsimple-112122722">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br>
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
<h2 id="anwendungsbereich" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Anwendungsbereich</h2>
<p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li class="adsimple-112122722">Social Media Auftritte und E-Mail-Kommunikation</li>
<li class="adsimple-112122722">mobile Apps für Smartphones und andere Geräte</li>
</ul>
<p><strong class="adsimple-112122722">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 id="rechtsgrundlagen" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br>
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li class="adsimple-112122722"><strong class="adsimple-112122722">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li class="adsimple-112122722"><strong class="adsimple-112122722">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
<li class="adsimple-112122722"><strong class="adsimple-112122722">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li class="adsimple-112122722"><strong class="adsimple-112122722">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">In <strong class="adsimple-112122722">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-112122722">Datenschutzgesetz</strong>), kurz <strong class="adsimple-112122722">DSG</strong>.</li>
<li class="adsimple-112122722">In <strong class="adsimple-112122722">Deutschland</strong> gilt das <strong class="adsimple-112122722">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-112122722"> BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
<h2 id="kontaktdaten-verantwortliche" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br>
<span class="adsimple-112122722" style="font-weight: 400">MARIA RATH</span></p>
<p>Kleinsteinbach 64<br>
A-8283 Bad Blumau</p>
<p>E-Mail: <a href="mailto:maria@gaestehaus-rath.at">maria@gaestehaus-rath.at</a><br>
Telefon: <a href="tel:+43 664 49 50 184">+43 664 49 50 184</a><br>
Impressum: <a href="https://gaestehaus-rath.at/impressum/">https://gaestehaus-rath.at/impressum/</a></p>
<h2 id="speicherdauer" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Speicherdauer</h2>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
<p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
<p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 id="rechte-dsgvo" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul class="adsimple-112122722">
<li class="adsimple-112122722">zu welchem Zweck wir die Verarbeitung durchführen;</li>
<li class="adsimple-112122722">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li class="adsimple-112122722">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li class="adsimple-112122722">wie lange die Daten gespeichert werden;</li>
<li class="adsimple-112122722">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li class="adsimple-112122722">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
<li class="adsimple-112122722">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li class="adsimple-112122722">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li class="adsimple-112122722">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
<li class="adsimple-112122722">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
<li class="adsimple-112122722">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
<li class="adsimple-112122722">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
<li class="adsimple-112122722">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
<ul class="adsimple-112122722">
<li class="adsimple-112122722">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
<li class="adsimple-112122722">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
<li class="adsimple-112122722">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
</ul>
</li>
<li class="adsimple-112122722">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
<li class="adsimple-112122722">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
</ul>
<p><strong class="adsimple-112122722">Kurz gesagt:</strong> Sie haben Rechte – zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a class="adsimple-112122722" href="https://www.dsb.gv.at/?tid=112122722" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-112122722" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
<h2 id="oesterreich-datenschutzbehoerde" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Österreich Datenschutzbehörde</h2>
<p><strong class="adsimple-112122722">Leiterin: </strong>Mag. Dr. Andrea Jelinek<strong class="adsimple-112122722"><br>
Adresse: </strong>Barichgasse 40-42, 1030 Wien<strong class="adsimple-112122722"><br>
Telefonnr.: </strong>+43 1 52&nbsp;152-0<strong class="adsimple-112122722"><br>
E-Mail-Adresse: </strong><a class="adsimple-112122722" href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener">dsb@dsb.gv.at</a><strong class="adsimple-112122722"><br>
Website: </strong><a class="adsimple-112122722" href="https://www.dsb.gv.at/" target="_blank" rel="noopener">https://www.dsb.gv.at/</a></p>
<h2 id="sicherheit-datenverarbeitung" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Sicherheit der Datenverarbeitung</h2>
<p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
<p>Art. 25 DSGVO spricht hier von “Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen” und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
<h2 id="tls-verschluesselung-https" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">TLS-Verschlüsselung mit https</h2>
<p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br>
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist – niemand kann “mithören”.</p>
<p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (<a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=112122722" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br>
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18"> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br>
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach “Hypertext Transfer Protocol Secure wiki” um gute Links zu weiterführenden Informationen zu erhalten.</p>
<h2 id="kommunikation" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Kommunikation</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td><strong class="adsimple-112122722">Kommunikation Zusammenfassung</strong><br>
👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br>
📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br>
🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br>
📅 Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br>
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Betroffene Personen</h3>
<p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Telefon</h3>
<p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">E-Mail</h3>
<p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,…) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Online Formulare</h3>
<p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Rechtsgrundlagen</h3>
<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
<li class="adsimple-112122722">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li class="adsimple-112122722">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
</ul>
<h2 id="webhosting-einleitung" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Webhosting Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td><strong class="adsimple-112122722">Webhosting Zusammenfassung</strong><br>
👥 Betroffene: Besucher der Website<br>
🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br>
📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br>
📅 Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br>
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Was ist Webhosting?</h3>
<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen – auch personenbezogene Daten – automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<p><img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%"></p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Warum verarbeiten wir personenbezogene Daten?</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li class="adsimple-112122722">Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li class="adsimple-112122722">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li class="adsimple-112122722">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
</ol>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Welche Daten werden verarbeitet?</h3>
<p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
<li class="adsimple-112122722">Browser und Browserversion (z. B. Chrome 87)</li>
<li class="adsimple-112122722">das verwendete Betriebssystem (z. B. Windows 10)</li>
<li class="adsimple-112122722">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a class="adsimple-112122722" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
<li class="adsimple-112122722">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li class="adsimple-112122722">Datum und Uhrzeit</li>
<li class="adsimple-112122722">in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Wie lange werden Daten gespeichert?</h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
<p><strong class="adsimple-112122722">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Rechtsgrundlage</h3>
<p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
<h2 id="webhosting-provider-extern" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Webhosting-Provider Extern Datenschutzerklärung</h2>
<p>мNachfolgend finden Sie die Kontaktdaten unseres externen Hosting-Providers, wo Sie, zusätzlich zu den Informationen oben, mehr zur Datenverarbeitung erfahren können:</p>
<p>easyname GmbH<br>
Canettistraße 5/10<br>
A-1100 Wien</p>
<p>Mehr über die Datenverarbeitung bei diesem Provider erfahren Sie in der <a class="adsimple-112122722" href="https://www.easyname.at/de/unternehmen/privacy-policy" target="_blank" rel="noopener">Datenschutzerklärung</a>.</p>
<h2 id="webdesign-einleitung" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Webdesign Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td><strong class="adsimple-112122722">Webdesign Datenschutzerklärung Zusammenfassung</strong><br>
👥 Betroffene: Besucher der Website<br>
🤝 Zweck: Verbesserung der Nutzererfahrung<br>
📓 Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen,&nbsp; Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den jeweils eingesetzten Webdesign-Tools.<br>
📅 Speicherdauer: abhängig von den eingesetzten Tools<br>
⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Was ist Webdesign?</h3>
<p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung. Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns. Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene Webdesign-Funktionen sein.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Warum verwenden wir Webdesign-Tools?</h3>
<p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich rundum wohl fühlen.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
<p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Dauer der Datenverarbeitung</h3>
<p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden. Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie den Support unter <a class="adsimple-112122722" href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier auf jeden Fall nochmals betonen.</p>
<p>Informationen zu speziellen Webdesign-Tools erhalten Sie – sofern vorhanden – in den folgenden Abschnitten.</p>
<h2 id="google-fonts-lokal-datenschutzerklaerung" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Google Fonts Lokal Datenschutzerklärung</h2>
<p>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.</p>
<h3 class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="21" style="--fontSize:21; line-height: 1.5; --minFontSize:21;" data-lineheight="31.5px">Was sind Google Fonts?</h3>
<p>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die <a class="adsimple-112122722" href="https://de.wikipedia.org/wiki/Google_LLC?tid=112122722">Google</a>&nbsp;kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.</p>
<h2 id="erklaerung-verwendeter-begriffe" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Erklärung verwendeter Begriffe</h2>
<p>Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu verfassen. Besonders bei technischen und rechtlichen Themen ist das allerdings nicht immer ganz einfach. Es macht oft Sinn juristische Begriffe (wie z. B. personenbezogene Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies, IP-Adresse) zu verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie nun eine alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der bisherigen Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls diese Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen handelt, werden wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene Erläuterungen hinzufügen.</p>
<h2 id="einwilligung" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Einwilligung</h2>
<p><strong class="adsimple-112122722">Begriffsbestimmung nach Artikel 4 der DSGVO</strong></p>
<p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
<blockquote>
<p><em><strong class="adsimple-112122722">„Einwilligung“</strong> der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist;</em></p>
</blockquote>
<p><strong class="adsimple-112122722">Erläuterung: </strong>In der Regel erfolgt bei Websites eine solche Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool, erfolgen.</p>
<h2 id="personenbezogene-daten" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Personenbezogene Daten</h2>
<p><strong class="adsimple-112122722">Begriffsbestimmung nach Artikel 4 der DSGVO</strong></p>
<p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
<blockquote>
<p><strong class="adsimple-112122722"><em>„personenbezogene Daten“</em></strong><em> alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann;</em></p>
</blockquote>
<p><strong class="adsimple-112122722">Erläuterung:</strong> Personenbezogene Daten sind also all jene Daten, die Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">Name</li>
<li class="adsimple-112122722">Adresse</li>
<li class="adsimple-112122722">E-Mail-Adresse</li>
<li class="adsimple-112122722">Post-Anschrift</li>
<li class="adsimple-112122722">Telefonnummer</li>
<li class="adsimple-112122722">Geburtsdatum</li>
<li class="adsimple-112122722">Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer, Personalausweisnummer oder Matrikelnummer</li>
<li class="adsimple-112122722">Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.</li>
</ul>
<p>Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre <strong class="adsimple-112122722">IP-Adresse zu den personenbezogenen Daten</strong>. IT-Experten können anhand Ihrer IP-Adresse zumindest den ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch sogenannte <strong class="adsimple-112122722">„besondere Kategorien“</strong> der personenbezogenen Daten, die auch besonders schützenswert sind. Dazu zählen:</p>
<ul class="adsimple-112122722">
<li class="adsimple-112122722">rassische und ethnische Herkunft</li>
<li class="adsimple-112122722">politische Meinungen</li>
<li class="adsimple-112122722">religiöse bzw. weltanschauliche Überzeugungen</li>
<li class="adsimple-112122722">die Gewerkschaftszugehörigkeit</li>
<li class="adsimple-112122722">genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben entnommen werden</li>
<li class="adsimple-112122722">biometrische Daten (das sind Informationen zu psychischen, körperlichen oder verhaltenstypischen Merkmalen, die eine Person identifizieren können).<br>
Gesundheitsdaten</li>
<li class="adsimple-112122722">Daten zur sexuellen Orientierung oder zum Sexualleben</li>
</ul>
<h2 id="profiling" class="adsimple-112122722 fusion-responsive-typography-calculated" data-fontsize="24" style="--fontSize:24; line-height: 1.5; --minFontSize:24;" data-lineheight="36px">Profiling</h2>
<p><strong class="adsimple-112122722">Begriffsbestimmung nach Artikel 4 der DSGVO</strong></p>
<p>Im Sinne dieser Verordnung bezeichnet der Ausdruck:</p>
<blockquote>
<p><em><strong class="adsimple-112122722">„Profiling“</strong> jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen;</em></p>
</blockquote>
<p><strong class="adsimple-112122722">Erläuterung:</strong> Beim Profiling werden verschiedene Informationen über eine Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich wird Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw. Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen Hilfe Werbung gezielt an eine Zielgruppe ausgespielt werden kann.</p>
<p>&nbsp;</p>
<p>Alle Texte sind urheberrechtlich geschützt.</p>
<p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich">Datenschutz Generator</a> von AdSimple</p>
<textarea class="copy-html" data-language-code="de" rows="20" style="width: 100%; outline: none; display: none;">&lt;h1 class="adsimple-112122722"&gt;Datenschutzerklärung&lt;/h1&gt;
&lt;h2 id="einleitung-ueberblick" class="adsimple-112122722"&gt;Einleitung und Überblick&lt;/h2&gt;
&lt;p&gt;Wir haben diese Datenschutzerklärung (Fassung 17.09.2022-112122722) verfasst, um Ihnen gemäß der Vorgaben der &lt;a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;amp;from=DE&amp;amp;tid=112122722#d1e2269-1-1" target="_blank" rel="noopener"&gt;Datenschutz-Grundverordnung (EU) 2016/679&lt;/a&gt; und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &amp;#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &amp;#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.&lt;br /&gt;
&lt;strong class="adsimple-112122722"&gt;Kurz gesagt:&lt;/strong&gt; Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.&lt;/p&gt;
&lt;p&gt;Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische &lt;strong class="adsimple-112122722"&gt;Begriffe leserfreundlich erklärt&lt;/strong&gt;, Links zu weiterführenden Informationen geboten und &lt;strong class="adsimple-112122722"&gt;Grafiken&lt;/strong&gt; zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.&lt;br /&gt;
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.&lt;/p&gt;
&lt;h2 id="anwendungsbereich" class="adsimple-112122722"&gt;Anwendungsbereich&lt;/h2&gt;
&lt;p&gt;Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;alle Onlineauftritte (Websites, Onlineshops), die wir betreiben&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Social Media Auftritte und E-Mail-Kommunikation&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;mobile Apps für Smartphones und andere Geräte&lt;/li&gt;
&lt;/ul&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Kurz gesagt:&lt;/strong&gt; Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.&lt;/p&gt;
&lt;h2 id="rechtsgrundlagen" class="adsimple-112122722"&gt;Rechtsgrundlagen&lt;/h2&gt;
&lt;p&gt;In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.&lt;br /&gt;
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter &lt;a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679"&gt;https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&lt;/a&gt; nachlesen.&lt;/p&gt;
&lt;p&gt;Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:&lt;/p&gt;
&lt;ol&gt;
&lt;li class="adsimple-112122722"&gt;
&lt;strong class="adsimple-112122722"&gt;Einwilligung&lt;/strong&gt; (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;
&lt;strong class="adsimple-112122722"&gt;Vertrag&lt;/strong&gt; (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;
&lt;strong class="adsimple-112122722"&gt;Rechtliche Verpflichtung&lt;/strong&gt; (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;
&lt;strong class="adsimple-112122722"&gt;Berechtigte Interessen&lt;/strong&gt; (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.&lt;/li&gt;
&lt;/ol&gt;
&lt;p&gt;Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.&lt;/p&gt;
&lt;p&gt;Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;In &lt;strong class="adsimple-112122722"&gt;Österreich&lt;/strong&gt; ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (&lt;strong class="adsimple-112122722"&gt;Datenschutzgesetz&lt;/strong&gt;), kurz &lt;strong class="adsimple-112122722"&gt;DSG&lt;/strong&gt;.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;In &lt;strong class="adsimple-112122722"&gt;Deutschland&lt;/strong&gt; gilt das &lt;strong class="adsimple-112122722"&gt;Bundesdatenschutzgesetz&lt;/strong&gt;, kurz&lt;strong class="adsimple-112122722"&gt; BDSG&lt;/strong&gt;.&lt;/li&gt;
&lt;/ul&gt;
&lt;p&gt;Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.&lt;/p&gt;
&lt;h2 id="kontaktdaten-verantwortliche" class="adsimple-112122722"&gt;Kontaktdaten des Verantwortlichen&lt;/h2&gt;
&lt;p&gt;Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:&lt;br /&gt;
&lt;span class="adsimple-112122722" style="font-weight: 400"&gt;Dr. med. univ.&lt;br /&gt;
MARIA LEGAT-RATH&lt;/p&gt;
&lt;p&gt;Mittergasse 10&lt;br /&gt;
A-6464 Tarrenz&lt;/span&gt;
&lt;/p&gt;
&lt;p&gt;E-Mail: &lt;a href="mailto:info@ordination-legat.at"&gt;info@ordination-legat.at&lt;/a&gt;
&lt;br /&gt;
Telefon: &lt;a href="tel:+43 5412 22232"&gt;+43 5412 22232&lt;/a&gt;
&lt;br /&gt;
Impressum: &lt;a href="https://ordination-legat.at/impressum/"&gt;https://ordination-legat.at/impressum/&lt;/a&gt;
&lt;/p&gt;
&lt;h2 id="speicherdauer" class="adsimple-112122722"&gt;Speicherdauer&lt;/h2&gt;
&lt;p&gt;Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.&lt;/p&gt;
&lt;p&gt;Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.&lt;/p&gt;
&lt;p&gt;Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.&lt;/p&gt;
&lt;h2 id="rechte-dsgvo" class="adsimple-112122722"&gt;Rechte laut Datenschutz-Grundverordnung&lt;/h2&gt;
&lt;p&gt;Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;zu welchem Zweck wir die Verarbeitung durchführen;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;die Kategorien, also die Arten von Daten, die verarbeitet werden;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;wie lange die Daten gespeichert werden;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.&lt;/li&gt;
&lt;/ul&gt;
&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.&lt;/li&gt;
&lt;/ul&gt;
&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.&lt;/li&gt;
&lt;/ul&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Kurz gesagt:&lt;/strong&gt; Sie haben Rechte &amp;#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!&lt;/p&gt;
&lt;p&gt;Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter &lt;a class="adsimple-112122722" href="https://www.dsb.gv.at/?tid=112122722" target="_blank" rel="noopener"&gt;https://www.dsb.gv.at/&lt;/a&gt; finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die &lt;a class="adsimple-112122722" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener"&gt;Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)&lt;/a&gt; wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:&lt;/p&gt;
&lt;h2 id="oesterreich-datenschutzbehoerde" class="adsimple-112122722"&gt;Österreich Datenschutzbehörde&lt;/h2&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Leiterin: &lt;/strong&gt;Mag. Dr. Andrea Jelinek&lt;strong class="adsimple-112122722"&gt;
&lt;br /&gt;
Adresse: &lt;/strong&gt;Barichgasse 40-42, 1030 Wien&lt;strong class="adsimple-112122722"&gt;
&lt;br /&gt;
Telefonnr.: &lt;/strong&gt;+43 1 52&nbsp;152-0&lt;strong class="adsimple-112122722"&gt;
&lt;br /&gt;
E-Mail-Adresse: &lt;/strong&gt;
&lt;a class="adsimple-112122722" href="mailto:dsb@dsb.gv.at" target="_blank" rel="noopener"&gt;dsb@dsb.gv.at&lt;/a&gt;
&lt;strong class="adsimple-112122722"&gt;
&lt;br /&gt;
Website: &lt;/strong&gt;
&lt;a class="adsimple-112122722" href="https://www.dsb.gv.at/" target="_blank" rel="noopener"&gt;https://www.dsb.gv.at/&lt;/a&gt;
&lt;/p&gt;
&lt;h2 id="sicherheit-datenverarbeitung" class="adsimple-112122722"&gt;Sicherheit der Datenverarbeitung&lt;/h2&gt;
&lt;p&gt;Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.&lt;/p&gt;
&lt;p&gt;Art. 25 DSGVO spricht hier von &amp;#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&amp;#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.&lt;/p&gt;
&lt;h2 id="tls-verschluesselung-https" class="adsimple-112122722"&gt;TLS-Verschlüsselung mit https&lt;/h2&gt;
&lt;p&gt;TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.&lt;br /&gt;
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &amp;#8211; niemand kann &amp;#8220;mithören&amp;#8221;.&lt;/p&gt;
&lt;p&gt;Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (&lt;a class="adsimple-112122722" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;amp;from=DE&amp;amp;tid=112122722" target="_blank" rel="noopener"&gt;Artikel 25 Absatz 1 DSGVO&lt;/a&gt;). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.&lt;br /&gt;
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol &lt;img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /&gt; links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.&lt;br /&gt;
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &amp;#8220;Hypertext Transfer Protocol Secure wiki&amp;#8221; um gute Links zu weiterführenden Informationen zu erhalten.&lt;/p&gt;
&lt;h2 id="kommunikation" class="adsimple-112122722"&gt;Kommunikation&lt;/h2&gt;
&lt;table border="1" cellpadding="15"&gt;
&lt;tbody&gt;
&lt;tr&gt;
&lt;td&gt;
&lt;strong class="adsimple-112122722"&gt;Kommunikation Zusammenfassung&lt;/strong&gt;
&lt;br /&gt;
&amp;#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren&lt;br /&gt;
&amp;#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart&lt;br /&gt;
&amp;#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.&lt;br /&gt;
&amp;#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften&lt;br /&gt;
&amp;#x2696;&amp;#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)&lt;/td&gt;
&lt;/tr&gt;
&lt;/tbody&gt;
&lt;/table&gt;
&lt;p&gt;Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.&lt;/p&gt;
&lt;p&gt;Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Betroffene Personen&lt;/h3&gt;
&lt;p&gt;Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Telefon&lt;/h3&gt;
&lt;p&gt;Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;E-Mail&lt;/h3&gt;
&lt;p&gt;Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&amp;#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Online Formulare&lt;/h3&gt;
&lt;p&gt;Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Rechtsgrundlagen&lt;/h3&gt;
&lt;p&gt;Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.&lt;/li&gt;
&lt;/ul&gt;
&lt;h2 id="webhosting-einleitung" class="adsimple-112122722"&gt;Webhosting Einleitung&lt;/h2&gt;
&lt;table border="1" cellpadding="15"&gt;
&lt;tbody&gt;
&lt;tr&gt;
&lt;td&gt;
&lt;strong class="adsimple-112122722"&gt;Webhosting Zusammenfassung&lt;/strong&gt;
&lt;br /&gt;
&amp;#x1f465; Betroffene: Besucher der Website&lt;br /&gt;
&amp;#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs&lt;br /&gt;
&amp;#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.&lt;br /&gt;
&amp;#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen&lt;br /&gt;
&amp;#x2696;&amp;#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)&lt;/td&gt;
&lt;/tr&gt;
&lt;/tbody&gt;
&lt;/table&gt;
&lt;h3 class="adsimple-112122722"&gt;Was ist Webhosting?&lt;/h3&gt;
&lt;p&gt;Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &amp;#8211; auch personenbezogene Daten &amp;#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.&lt;/p&gt;
&lt;p&gt;Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.&lt;/p&gt;
&lt;p&gt;Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!&lt;/p&gt;
&lt;p&gt;Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.&lt;/p&gt;
&lt;p&gt;Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.&lt;/p&gt;
&lt;p&gt;
&lt;img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" /&gt;
&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Warum verarbeiten wir personenbezogene Daten?&lt;/h3&gt;
&lt;p&gt;Die Zwecke der Datenverarbeitung sind:&lt;/p&gt;
&lt;ol&gt;
&lt;li class="adsimple-112122722"&gt;Professionelles Hosting der Website und Absicherung des Betriebs&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;zur Aufrechterhaltung der Betriebs- und IT-Sicherheit&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen&lt;/li&gt;
&lt;/ol&gt;
&lt;h3 class="adsimple-112122722"&gt;Welche Daten werden verarbeitet?&lt;/h3&gt;
&lt;p&gt;Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;die komplette Internetadresse (URL) der aufgerufenen Webseite&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Browser und Browserversion (z. B. Chrome 87)&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;das verwendete Betriebssystem (z. B. Windows 10)&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. &lt;a class="adsimple-112122722" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="follow noopener"&gt;https://www.beispielquellsite.de/vondabinichgekommen/&lt;/a&gt;)&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Datum und Uhrzeit&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;in Dateien, den sogenannten Webserver-Logfiles&lt;/li&gt;
&lt;/ul&gt;
&lt;h3 class="adsimple-112122722"&gt;Wie lange werden Daten gespeichert?&lt;/h3&gt;
&lt;p&gt;In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.&lt;/p&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Kurz gesagt:&lt;/strong&gt; Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Rechtsgrundlage&lt;/h3&gt;
&lt;p&gt;Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.&lt;/p&gt;
&lt;p&gt;Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.&lt;/p&gt;
&lt;h2 id="webhosting-provider-extern" class="adsimple-112122722"&gt;Webhosting-Provider Extern Datenschutzerklärung&lt;/h2&gt;
&lt;p&gt;мNachfolgend finden Sie die Kontaktdaten unseres externen Hosting-Providers, wo Sie, zusätzlich zu den Informationen oben, mehr zur Datenverarbeitung erfahren können:&lt;/p&gt;
&lt;p&gt;easyname GmbH&lt;br /&gt;
Canettistraße 5/10&lt;br /&gt;
A-1100 Wien&lt;/p&gt;
&lt;p&gt;Mehr über die Datenverarbeitung bei diesem Provider erfahren Sie in der &lt;a class="adsimple-112122722" href="https://www.easyname.at/de/unternehmen/privacy-policy" target="_blank" rel="noopener"&gt;Datenschutzerklärung&lt;/a&gt;.&lt;/p&gt;
&lt;h2 id="webdesign-einleitung" class="adsimple-112122722"&gt;Webdesign Einleitung&lt;/h2&gt;
&lt;table border="1" cellpadding="15"&gt;
&lt;tbody&gt;
&lt;tr&gt;
&lt;td&gt;
&lt;strong class="adsimple-112122722"&gt;Webdesign Datenschutzerklärung Zusammenfassung&lt;/strong&gt;
&lt;br /&gt;
&amp;#x1f465; Betroffene: Besucher der Website&lt;br /&gt;
&amp;#x1f91d; Zweck: Verbesserung der Nutzererfahrung&lt;br /&gt;
&amp;#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen,&nbsp; Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den jeweils eingesetzten Webdesign-Tools.&lt;br /&gt;
&amp;#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools&lt;br /&gt;
&amp;#x2696;&amp;#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)&lt;/td&gt;
&lt;/tr&gt;
&lt;/tbody&gt;
&lt;/table&gt;
&lt;h3 class="adsimple-112122722"&gt;Was ist Webdesign?&lt;/h3&gt;
&lt;p&gt;Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung. Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns. Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene Webdesign-Funktionen sein.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Warum verwenden wir Webdesign-Tools?&lt;/h3&gt;
&lt;p&gt;Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich rundum wohl fühlen.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Welche Daten werden durch Webdesign-Tools gespeichert?&lt;/h3&gt;
&lt;p&gt;Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Dauer der Datenverarbeitung&lt;/h3&gt;
&lt;p&gt;Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden. Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Widerspruchsrecht&lt;/h3&gt;
&lt;p&gt;Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie den Support unter &lt;a class="adsimple-112122722" href="https://support.google.com/?hl=de"&gt;https://support.google.com/?hl=de&lt;/a&gt;.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Rechtsgrundlage&lt;/h3&gt;
&lt;p&gt;Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier auf jeden Fall nochmals betonen.&lt;/p&gt;
&lt;p&gt;Informationen zu speziellen Webdesign-Tools erhalten Sie &amp;#8211; sofern vorhanden &amp;#8211; in den folgenden Abschnitten.&lt;/p&gt;
&lt;h2 id="google-fonts-lokal-datenschutzerklaerung" class="adsimple-112122722"&gt;Google Fonts Lokal Datenschutzerklärung&lt;/h2&gt;
&lt;p&gt;Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver &amp;#8211; nicht auf den Servern von Google &amp;#8211; eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.&lt;/p&gt;
&lt;h3 class="adsimple-112122722"&gt;Was sind Google Fonts?&lt;/h3&gt;
&lt;p&gt;Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die &lt;a class="adsimple-112122722" href="https://de.wikipedia.org/wiki/Google_LLC?tid=112122722"&gt;Google&lt;/a&gt;&nbsp;kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.&lt;/p&gt;
&lt;h2 id="erklaerung-verwendeter-begriffe" class="adsimple-112122722"&gt;Erklärung verwendeter Begriffe&lt;/h2&gt;
&lt;p&gt;Wir sind stets bemüht unsere Datenschutzerklärung so klar und verständlich wie möglich zu verfassen. Besonders bei technischen und rechtlichen Themen ist das allerdings nicht immer ganz einfach. Es macht oft Sinn juristische Begriffe (wie z. B. personenbezogene Daten) oder bestimmte technische Ausdrücke (wie z. B. Cookies, IP-Adresse) zu verwenden. Wir möchte diese aber nicht ohne Erklärung verwenden. Nachfolgend finden Sie nun eine alphabetische Liste von wichtigen verwendeten Begriffen, auf die wir in der bisherigen Datenschutzerklärung vielleicht noch nicht ausreichend eingegangen sind. Falls diese Begriffe der DSGVO entnommen wurden und es sich um Begriffsbestimmungen handelt, werden wir hier auch die DSGVO-Texte anführen und gegebenenfalls noch eigene Erläuterungen hinzufügen.&lt;/p&gt;
&lt;h2 id="einwilligung" class="adsimple-112122722"&gt;Einwilligung&lt;/h2&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Begriffsbestimmung nach Artikel 4 der DSGVO&lt;/strong&gt;
&lt;/p&gt;
&lt;p&gt;Im Sinne dieser Verordnung bezeichnet der Ausdruck:&lt;/p&gt;
&lt;blockquote&gt;
&lt;p&gt;
&lt;em&gt;
&lt;strong class="adsimple-112122722"&gt;„Einwilligung“&lt;/strong&gt; der betroffenen Person jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist;&lt;/em&gt;
&lt;/p&gt;
&lt;/blockquote&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Erläuterung: &lt;/strong&gt;In der Regel erfolgt bei Websites eine solche Einwilligung über ein Cookie-Consent-Tool. Sie kennen das bestimmt. Immer wenn Sie erstmals eine Website besuchen, werden Sie meist über einen Banner gefragt, ob Sie der Datenverarbeitung zustimmen bzw. einwilligen. Meist können Sie auch individuelle Einstellungen treffen und so selbst entscheiden, welche Datenverarbeitung Sie erlauben und welche nicht. Wenn Sie nicht einwilligen, dürfen auch keine personenbezogene Daten von Ihnen verarbeitet werden. Grundsätzlich kann eine Einwilligung natürlich auch schriftlich, also nicht über ein Tool, erfolgen.&lt;/p&gt;
&lt;h2 id="personenbezogene-daten" class="adsimple-112122722"&gt;Personenbezogene Daten&lt;/h2&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Begriffsbestimmung nach Artikel 4 der DSGVO&lt;/strong&gt;
&lt;/p&gt;
&lt;p&gt;Im Sinne dieser Verordnung bezeichnet der Ausdruck:&lt;/p&gt;
&lt;blockquote&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;
&lt;em&gt;„personenbezogene Daten“&lt;/em&gt;
&lt;/strong&gt;
&lt;em&gt; alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann;&lt;/em&gt;
&lt;/p&gt;
&lt;/blockquote&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Erläuterung:&lt;/strong&gt; Personenbezogene Daten sind also all jene Daten, die Sie als Person identifizieren können. Das sind in der Regel Daten wie etwa:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;Name&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Adresse&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;E-Mail-Adresse&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Post-Anschrift&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Telefonnummer&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Geburtsdatum&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Kennnummern wie Sozialversicherungsnummer, Steueridentifikationsnummer, Personalausweisnummer oder Matrikelnummer&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Bankdaten wie Kontonummer, Kreditinformationen, Kontostände uvm.&lt;/li&gt;
&lt;/ul&gt;
&lt;p&gt;Laut Europäischem Gerichtshof (EuGH) zählt auch Ihre &lt;strong class="adsimple-112122722"&gt;IP-Adresse zu den personenbezogenen Daten&lt;/strong&gt;. IT-Experten können anhand Ihrer IP-Adresse zumindest den ungefähren Standort Ihres Geräts und in weiterer Folge Sie als Anschlussinhabers feststellen. Daher benötigt auch das Speichern einer IP-Adresse eine Rechtsgrundlage im Sinne der DSGVO. Es gibt auch noch sogenannte &lt;strong class="adsimple-112122722"&gt;„besondere Kategorien“&lt;/strong&gt; der personenbezogenen Daten, die auch besonders schützenswert sind. Dazu zählen:&lt;/p&gt;
&lt;ul class="adsimple-112122722"&gt;
&lt;li class="adsimple-112122722"&gt;rassische und ethnische Herkunft&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;politische Meinungen&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;religiöse bzw. weltanschauliche Überzeugungen&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;die Gewerkschaftszugehörigkeit&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;genetische Daten wie beispielsweise Daten, die aus Blut- oder Speichelproben entnommen werden&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;biometrische Daten (das sind Informationen zu psychischen, körperlichen oder verhaltenstypischen Merkmalen, die eine Person identifizieren können).&lt;br /&gt;
Gesundheitsdaten&lt;/li&gt;
&lt;li class="adsimple-112122722"&gt;Daten zur sexuellen Orientierung oder zum Sexualleben&lt;/li&gt;
&lt;/ul&gt;
&lt;h2 id="profiling" class="adsimple-112122722"&gt;Profiling&lt;/h2&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Begriffsbestimmung nach Artikel 4 der DSGVO&lt;/strong&gt;
&lt;/p&gt;
&lt;p&gt;Im Sinne dieser Verordnung bezeichnet der Ausdruck:&lt;/p&gt;
&lt;blockquote&gt;
&lt;p&gt;
&lt;em&gt;
&lt;strong class="adsimple-112122722"&gt;„Profiling“&lt;/strong&gt; jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen;&lt;/em&gt;
&lt;/p&gt;
&lt;/blockquote&gt;
&lt;p&gt;
&lt;strong class="adsimple-112122722"&gt;Erläuterung:&lt;/strong&gt; Beim Profiling werden verschiedene Informationen über eine Person zusammengetragen, um daraus mehr über diese Person zu erfahren. Im Webbereich wird Profiling häufig für Werbezwecke oder auch für Bonitätsprüfungen angewandt. Web- bzw. Werbeanalyseprogramme sammeln zum Beispiel Daten über Ihre Verhalten und Ihre Interessen auf einer Website. Daraus ergibt sich ein spezielles Userprofil, mit dessen Hilfe Werbung gezielt an eine Zielgruppe ausgespielt werden kann.&lt;/p&gt;
&lt;p&gt;&amp;nbsp;&lt;/p&gt;
&lt;p&gt;Alle Texte sind urheberrechtlich geschützt.&lt;/p&gt;
&lt;p style="margin-top:15px"&gt;Quelle: Erstellt mit dem &lt;a href="https://www.adsimple.at/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Österreich"&gt;Datenschutz Generator&lt;/a&gt; von AdSimple&lt;/p&gt;
</textarea></div>
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScHeader3>Datenschutzerklärung</ScHeader3>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
